import React from 'react';
import config from '../../config/config';
import { generateTriangleLink } from '../../utilities/helpers';
import { useNavigate } from 'react-router-dom';

const ImageTriangles = ({
  index,
  photo_path = null,
  type_id = null,
  id = null,
  hidden = false,
}) => {
  let navigate = useNavigate();

  if (hidden) {
    return null;
  }

  const backgroundCode = index % 2 == 0 ? 1 : 0;

  const parsedLink = generateTriangleLink(type_id, id);

  return (
    <div data-depth="0.3" className={`triangle-wrapper ti-${index}`}>
      <div
        className={`triangle pointer ${
          !!type_id ? 'grow' : ''
        } bg-${backgroundCode}`}
      >
        {!!type_id && (
          <div
            className="triangle-link"
            onClick={() => navigate(parsedLink, { state: { welcome: true } })}
          >
            <img src={`${config.thumbRoute}/${photo_path}`} alt={'photo'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageTriangles;
