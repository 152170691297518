const getNumberBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const handleExpand = (evt) => {
  const boxes = document.querySelectorAll('.filter-box');
  const target = evt.target;
  const parentBox = evt.target.closest('.filter-box');
  const active = document.activeElement;

  if (active.classList.contains('tylin-input')) {
    active.blur();
  }

  if (parentBox.classList.contains('grow')) {
    parentBox.classList.remove('grow');
    target.classList.remove('grow');
  } else {
    boxes.forEach((box) => {
      box.classList.remove('grow');
      box.querySelector('.filter-title').classList.remove('grow');
    });

    target.classList.toggle('grow');
    parentBox.classList.toggle('grow');
  }
};

const handleCloseOpened = () => {
  const boxes = document.querySelectorAll('.filter-box.grow');
  const active = document.activeElement;

  if (active.classList.contains('tylin-input')) {
    active.blur();
  }

  boxes.forEach((box) => {
    box.classList.remove('grow');
    box.querySelector('.filter-title').classList.remove('grow');
  });
};

const makeSingleSelect = (value) => {
  if (value.length === 0) {
    return [];
  } else {
    return [value.pop()];
  }
};

const generateTriangleLink = (type, id) => {
  if (type === 10) {
    return `/profile/${id}`;
  } else {
    return `/article/${id}`;
  }
};

export {
  getNumberBetween,
  handleExpand,
  makeSingleSelect,
  generateTriangleLink,
  handleCloseOpened,
};
