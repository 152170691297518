import React, { useEffect, useRef } from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { MultiSelect } from 'react-multi-select-component';

const FilterWithSelect = ({ title, name, expand, change, options, value }) => {
  return (
    <div
      style={{ position: 'relative', margin: '4px' }}
      className="filter-element"
    >
      <p className="filter-title-spacer">{title}</p>
      <div className="filter-box">
        <div
          className={`value-indicator ${value.length > 0 ? 'hasValue' : ''}`}
        >
          <FaCheck />
        </div>
        <p className="filter-title" onClick={expand}>
          {title}
        </p>
        <div className="filter-clear">
          <FaTimes />
        </div>
        <div style={{ padding: '10px' }}>
          <MultiSelect
            options={options}
            value={value}
            onChange={(value) => change(name, value)}
            labelledBy="Select"
            className="basic-multi-select value-input"
          />
        </div>
      </div>
    </div>
  );
};
export default FilterWithSelect;
