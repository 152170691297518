import { getNumberBetween } from '../utilities/helpers';

const animateTriangles = (direction) => {
  const triangles = [...document.querySelectorAll('.triangle-wrapper')];

  if (direction === 'in') {
    triangles.forEach((tri, index) => {
      const list = tri.classList;
      tri.className = `${list[0]} ${
        list[1]
      } animate__animated animate__fadeInLeft animate__${
        index % 2 == 0 ? 'fast' : 'faster'
      }`;

      alterPosition(tri);

      setTimeout(() => {
        removeAnimation();
      }, 900);
    });
  } else if (direction === 'out') {
    triangles.forEach((tri, index) => {
      const list = tri.classList;
      tri.className = `${list[0]} ${
        list[1]
      } animate__animated animate__fadeOutRight animate__${
        index % 2 == 0 ? 'fast' : 'faster'
      }`;
    });
  }
  return;
};

const removeAnimation = () => {
  const triangles = [...document.querySelectorAll('.triangle-wrapper')];
  triangles.forEach((tri, index) => {
    const list = tri.classList;
    tri.className = `${list[0]} ${list[1]}`;
  });
};

const alterPosition = (triangle) => {
  const x = parseInt(triangle.dataset.x);
  const y = parseInt(triangle.dataset.y);
  const ry = getNumberBetween(y - 20, y + 20);
  const rx = getNumberBetween(x - 20, x + 20);

  if (ry > 0 && ry < 520) {
    triangle.setAttribute('style', `top: ${ry}px !important`);
    triangle.style.top = ry + 'px';
  }

  if (rx > 0 && rx < 530) {
    triangle.setAttribute('style', `left: ${rx}px !important`);
  }
};

export default animateTriangles;
