import React, { useEffect, useState } from 'react';
import Checkbox from 'react-custom-checkbox';
import { MultiSelect } from 'react-multi-select-component';
import { makeSingleSelect } from '../../utilities/helpers';
import { FaCheck } from 'react-icons/fa';
import { defaultItem } from '../form/customSelectHelpers';
import config from '../../config/config';
import { getCountries, postRegisterForm } from '../../utilities/axios';
import {
  optionsToValues,
  selectValueFromOptions,
} from '../../utilities/optionsHelpers';

const RegisterStepOne = ({
  formData,
  setStep,
  parseFormData,
  setLoading,
  authDispatch,
}) => {
  const [cookie, setCookie] = useState(false);
  const [toc, setToc] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (cookie && toc && country.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [cookie, toc, country]);

  useEffect(() => {
    if (Object.keys(formData).length > 0 && countryOptions.length > 0) {
      const result = selectValueFromOptions(formData.country, countryOptions);
      setCountry(result);
      setCookie(!!formData.cookie);
      setToc(!!formData.toc);
    }
  }, [formData, countryOptions]);

  const fetchCountries = async () => {
    const options = await getCountries();

    setCountryOptions(options);
  };

  const handleNext = async () => {
    const form = {
      cookie,
      toc,
      country: optionsToValues(country)[0],
    };

    const result = await postRegisterForm(form);

    if (result === 401) {
      authDispatch({ type: 'LOG_OUT' });
    } else if (!result.error) {
      parseFormData(result);
      setStep(2);
    }
  };

  return (
    <div>
      <div className="med-container">
        <p>{country.latdbel}</p>
        <h2 style={{ textAlign: 'center' }}>Let's get to know you</h2>
        <p className="letter-space-1 gray">
          You are invited to participate in TYLin Connect, an interactive,
          digital platform that unites TYLin colleagues from all around the
          world. Please fill out the following short questionnaire and upload a
          profile photo. The answers and image you submit will be used to build
          your unique profile on TYLin Connect.
        </p>
        <p className="letter-space-1 gray">
          If you have any questions, please contact
          <a href="mailto:tylininfo@tylin.com" style={{ fontWeight: 'bold' }}>
            {' tylininfo@tylin.com'}
          </a>
        </p>
        <div className="flex align-items-center">
          <p className="letter-space-1 gray" style={{ marginRight: '10px' }}>
            Please select your country
          </p>
          <MultiSelect
            options={countryOptions}
            value={country}
            onChange={(value) => setCountry(makeSingleSelect(value))}
            hasSelectAll={false}
            labelledBy="country select"
            className="gray-multi-select med-select"
            ItemRenderer={defaultItem}
          />
        </div>
        <div className="flex align-items-center justify-content-between">
          <p className="letter-space-1 gray">
            TYLin Connect uses cookies to provide necessary website
            functionality. Check here to indicate that you agree to our{' '}
            <a href={config.privacyPolicyLink} target="_blank">
              Privacy Policy
            </a>{' '}
            and our cookies usage.
          </p>
          <Checkbox
            icon={
              <div
                className="flex justify-content-center align-items-center"
                style={{ color: '#f26724' }}
              >
                <FaCheck />
              </div>
            }
            onChange={(value) => setCookie(value)}
            checked={cookie}
            size={25}
            borderRadius={0}
            borderWidth={0}
            style={{ background: '#e2e2e2' }}
            containerStyle={{ cursor: 'pointer' }}
          />
        </div>
        <div className="flex align-items-center justify-content-between">
          <p className="letter-space-1 gray">
            Check here to indicate that you consent to the use of your
            information in accordance with the terms and conditions of the{' '}
            <a href={config.privacyPolicyLink} target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          <Checkbox
            icon={
              <div
                className="flex justify-content-center align-items-center"
                style={{ color: '#f26724' }}
              >
                <FaCheck />
              </div>
            }
            onChange={(value) => setToc(value)}
            checked={toc}
            size={25}
            borderRadius={0}
            borderWidth={0}
            style={{ background: '#e2e2e2' }}
            containerStyle={{ cursor: 'pointer' }}
          />
        </div>
        <div className="flex justify-content-center">
          <button
            className="tylin-button"
            onClick={() => {
              setLoading(true);
              handleNext();
            }}
            disabled={disabled}
            style={{ opacity: `${disabled ? 0.5 : 1}` }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterStepOne;
