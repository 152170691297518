import React from 'react';

const RegisterTextInput = ({
  change,
  value,
  title,
  required = false,
  field,
  disabled = false,
}) => {
  return (
    <div className="flex align-items-center">
      <p
        className="letter-space-1 gray"
        style={{ margin: '12px 20px 12px 0', whiteSpace: 'nowrap' }}
      >
        {' ' + title}
        {required ? <span className="orange">*</span> : ''}
      </p>
      <input
        type="text"
        className="tylin-input gray-background"
        onChange={(evt) => change(evt.target.value, field)}
        value={value || ''}
        name={field}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export default RegisterTextInput;
