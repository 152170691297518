import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SpinnerRound } from 'spinners-react';
import { verfiyEmailHash } from '../actions/authActions';
import { useAuthStore } from '../store/AuthStore';
import { Navigate } from 'react-router-dom';

const InboundLoader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, dispatch] = useAuthStore();
  const hash = searchParams.get('email');
  const source = searchParams.get('source');

  useEffect(() => {
    validateUser();
  }, []);

  const validateUser = async () => {
    const result = await verfiyEmailHash({ hash, source }, source);
    dispatch(result);
    return;
  };

  return (
    <div
      className="flex align-items-center justify-content-center"
      style={{ width: '100%', height: '100vh', margin: 'auto' }}
    >
      {state.isLoggedIn && <Navigate to="/register" />}
      {state.error ? (
        <p>{state.error}</p>
      ) : (
        <SpinnerRound color="orange" height={80} width={80} />
      )}
    </div>
  );
};

export default InboundLoader;
