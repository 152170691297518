import axios from 'axios';
import config from '../config/config';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  crossDomain: true,
  timeout: 1000 * 20, // 20 second timeout
};

export const verfiyEmailHash = async (params, source) => {
  try {
    const { data } = await axios.post(
      `${config.verifyHashUrl}`, //'https://api.tylinconnect.com/hashes/validate.json'
      params,
      axiosConfig
    );

    if (data.validation.valid) {
      return {
        type: 'LOGIN_SUCCESS',
        isLoggedIn: true,
        isRegistered: data.validation.registered,
      };
    } else {
      return {
        type: 'LOGIN_FAIL',
        isLoggedIn: false,
        error: 'Unauthorized.',
      };
    }
  } catch (err) {
    console.log(err);
    return {
      type: 'LOGIN_FAIL',
      isLoggedIn: false,
      error: 'Authorization failed.',
    };
  }
};

export const updateAuthRegistered = () => ({ type: 'UPDATE_AUTH_REGISTERED' });
