import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';
import 'animate.css';
import { AuthStoreProvider } from './store/AuthStore';
import { authReducer, initialState } from './reducers/authReducer';

ReactDOM.render(
  <React.StrictMode>
    <AuthStoreProvider initialState={initialState} reducer={authReducer}>
      <AppRouter />
    </AuthStoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
