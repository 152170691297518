import React from 'react';

const FilterDivider = ({ title }) => {
  return (
    <div>
      <div className="flex justify-content-center align-items-center">
        <div
          className="dividerLines"
          style={{ width: '100%', paddingRight: '8px' }}
        ></div>
        <p className="dividerTitle">{title}</p>
        <div
          className="dividerLines"
          style={{ width: '100%', paddingLeft: '8px' }}
        ></div>
      </div>
    </div>
  );
};

export default FilterDivider;
