import React, { useRef } from 'react';
import { postUploadFile } from '../../utilities/axios';

const RegisterFileUpload = ({ parseFormData, setError, authDispatch }) => {
  const fileInput = useRef(null);

  const handleFileInput = async (evt) => {
    const file = evt.target.files[0];

    if (file.type !== 'image/jpeg') {
      setError('Must be JPG/JPEG.');
      return;
    } else if (file.size < 100_000) {
      setError('File too small.');
      return;
    } else if (file.size > 10_485_760) {
      setError('File too large.');
      return;
    }

    let formData = new FormData();
    formData.append('photo', file);

    const result = await postUploadFile(formData);

    setError('');

    if (result === 401) {
      authDispatch({ type: 'LOG_OUT' });
    } else if (!result.error) {
      parseFormData(result);
    }
  };

  return (
    <div className="file-uploader">
      <input
        type="file"
        onChange={handleFileInput}
        ref={fileInput}
        style={{ display: 'none' }}
      />
      <button
        onClick={() => fileInput.current.click()}
        className="tylin-button"
        type="button"
      >
        Upload
      </button>
    </div>
  );
};

export default RegisterFileUpload;
