import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import RegisterStepOne from '../components/register/RegisterStepOne';
import RegisterStepThree from '../components/register/RegisterStepThree';
import RegisterStepTwo from '../components/register/RegisterStepTwo';
import RegisterThankYou from '../components/register/RegisterThankYou';
import { useAuthStore } from '../store/AuthStore';
import { getUserInfo } from '../utilities/axios';

const Register = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useAuthStore();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (state.isRegistered) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    const result = await getUserInfo();

    if (result === 401) {
      return dispatch({ type: 'LOG_OUT' });
    }

    parseFormData(result);
    setLoading(false);
  };

  const parseFormData = (rawTriangle) => {
    const userInfo = {
      firstName: rawTriangle.first_name || '',
      lastName: rawTriangle.last_name || '',
      country: !!rawTriangle.country_id ? [rawTriangle.country_id] : [],
      cookie: !!rawTriangle.cookie_timestamp,
      toc: !!rawTriangle.toc_timestamp || '',
      office: !!rawTriangle.office_id ? [rawTriangle.office_id] : [],
      duration: rawTriangle.duration || 0,
      jobTitle: rawTriangle.job_title || '',
      discipline: !!rawTriangle.disciplines
        ? rawTriangle.disciplines.map((value) => value.id)
        : [],
      experience: !!rawTriangle.experiences
        ? rawTriangle.experiences.map((value) => value.id)
        : [],
      hobbies: !!rawTriangle.hobbies
        ? rawTriangle.hobbies.map((value) => value.id)
        : [],
      summary: rawTriangle.summary || '',
      photo_path: rawTriangle.photo_path || '',
    };

    setFormData(userInfo);
  };

  return (
    <div className={'page-container'} stlye={{ position: 'relative' }}>
      <MainHeader />
      <div className="flex white-container">
        <img
          src="/images/background.png"
          alt="background"
          className="background-triangles"
        />
        <div style={{ margin: '0 auto' }}>
          <div className={`whiteOverlay ${loading ? 'show' : ''}`}></div>
          {!state.isRegistered && step === 1 && (
            <RegisterStepOne
              setLoading={setLoading}
              parseFormData={parseFormData}
              formData={formData}
              setStep={setStep}
              authDispatch={dispatch}
            />
          )}
          {!state.isRegistered && step === 2 && (
            <RegisterStepTwo
              setLoading={setLoading}
              parseFormData={parseFormData}
              formData={formData}
              setStep={setStep}
              authDispatch={dispatch}
            />
          )}
          {!state.isRegistered && step === 3 && (
            <RegisterStepThree
              setLoading={setLoading}
              parseFormData={parseFormData}
              formData={formData}
              setStep={setStep}
              authDispatch={dispatch}
            />
          )}
          {state.isRegistered && <RegisterThankYou />}
        </div>
      </div>
    </div>
  );
};

export default Register;
