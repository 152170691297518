export const initialState = {
  isLoggedIn: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        isLoggedIn: true,
        isRegistered: action.isRegistered,
      };

    case 'LOGIN_FAIL':
      return {
        isLoggedIn: false,
        error: action.error,
      };

    case 'UPDATE_AUTH_REGISTERED':
      return {
        ...state,
        isRegistered: true,
      };

    case 'LOG_OUT':
      return initialState;

    case 'RESET_AUTH':
      return initialState;

    default:
      return state;
  }
};
