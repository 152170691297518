import React from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';

const FilterWithRange = ({ name, expand, title, change, value }) => {
  const handleValue = (value) => {
    if (value === '') {
      return '0';
    } else if (typeof value === 'string') {
      return `${parseInt(value)}`;
    } else if (typeof value === 'number') {
      return `${value}`;
    }
  };

  return (
    <div
      style={{ position: 'relative', margin: '4px' }}
      className="filter-element"
    >
      <div className="filter-title-spacer">{title}</div>
      <div className="filter-box">
        <div
          className={`value-indicator ${
            value !== '0' && value !== 0 ? 'hasValue' : ''
          }`}
        >
          <FaCheck />
        </div>
        <p className="filter-title" onClick={expand}>
          {title}
        </p>
        <div className="filter-clear">
          <FaTimes />
        </div>
        <div style={{ padding: '10px' }}>
          <input
            className="tylin-input value-only value-input"
            value={value}
            min={0}
            max={50}
            type={'number'}
            onChange={(evt) => change(name, handleValue(evt.target.value))}
          />
          <div style={{ padding: '0 16px' }}>
            <input
              name={name}
              type="range"
              onChange={(evt) => change(name, handleValue(evt.target.value))}
              value={value}
              min={0}
              max={50}
            />
            <div
              className="flex space-between"
              style={{ marginTop: '-8px', color: 'black' }}
            >
              <small>{'0'}</small>
              <small>{'>50'}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWithRange;
