import React, { useContext, useEffect, useReducer } from 'react';

const AuthStore = React.createContext();
AuthStore.displayName = 'AuthStore';

export const useAuthStore = () => useContext(AuthStore);

export const AuthStoreProvider = ({ children, initialState, reducer }) => {
  const [authState, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthStore.Provider value={[authState, dispatch]}>
      {children}
    </AuthStore.Provider>
  );
};
