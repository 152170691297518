import React from 'react';
import triangleConfig from '../../config/triangleConfig';

const BackgroundTriangles = ({ index, triangle }) => {
  const x = Math.round(triangleConfig.containerX * triangle.x);
  const y = Math.round(triangleConfig.containerY * triangle.y);

  return (
    <div className={`triangle-wrapper tc-${index}`} data-x={x} data-y={y}>
      <div className={`triangle`}></div>
    </div>
  );
};

export default BackgroundTriangles;
