// Coordinates for the backgorund and link triangles

const triangleConfig = {
  containerY: 640,
  containerX: 640,
  backgroundTriangles: [
    {
      y: 0.38,
      x: 0.21,
    },
    {
      y: 0.03,
      x: 0.58,
    },
    {
      y: 0.05,
      x: 0,
    },
    {
      y: 0.52,
      x: 0.83,
    },
    {
      y: 0.75,
      x: 0,
    },
    {
      y: 0.78,
      x: 0.37,
    },
    {
      y: 0.25,
      x: 0.75,
    },
    {
      y: 0.63,
      x: 0.05,
    },
  ],
  linkTriangles: [
    {
      y: 0.28,
      x: 0.63,
    },
    {
      y: 0.55,
      x: 0.13,
    },
    {
      y: 0.14,
      x: 0.05,
    },
    {
      y: 0.49,
      x: 0.3,
    },
  ],
};

export default triangleConfig;
