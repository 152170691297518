import React, { useState, useEffect } from 'react';
import {
  getDisciplines,
  getExperiences,
  getHobbies,
  postRegisterForm,
  getOffices,
} from '../../utilities/axios';
import {
  optionsToValues,
  selectValueFromOptions,
} from '../../utilities/optionsHelpers';
import RegisterNumberInput from '../form/RegisterNumberInput';
import RegisterSelectInput from '../form/RegisterSelectInput';
import RegisterTextInput from '../form/RegisterTextInput';

const RegisterStepTwo = ({
  formData,
  setStep,
  setLoading,
  parseFormData,
  authDispatch,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [office, setOffice] = useState([]);
  const [duration, setDuration] = useState(0);
  const [job_title, setJobTitle] = useState('');
  const [discipline, setDiscipline] = useState([]);
  const [experience, setExperience] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [summary, setSummary] = useState('');
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [hobbieOptions, setHobbieOptions] = useState([]);
  const [officeOptions, setOfficeOptions] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (
      Object.keys(formData).length > 0 &&
      experienceOptions.length > 0 &&
      disciplineOptions.length > 0 &&
      hobbieOptions.length > 0 &&
      officeOptions.length > 0
    ) {
      setHobbies(
        selectValueFromOptions(
          !!formData.hobbies ? formData.hobbies : [],
          hobbieOptions
        )
      );
      setExperience(
        selectValueFromOptions(
          !!formData.experience ? formData.experience : [],
          experienceOptions
        )
      );
      setDiscipline(
        selectValueFromOptions(
          !!formData.discipline ? formData.discipline : [],
          disciplineOptions
        )
      );

      setOffice(
        selectValueFromOptions(
          !!formData.office ? formData.office : [],
          officeOptions
        )
      );
      setLoading(false);
    }
  }, [experienceOptions, disciplineOptions, hobbieOptions, officeOptions]);

  useEffect(() => {
    setFirstName(formData.firstName || '');
    setLastName(formData.lastName || '');
    setDuration(formData.duration || 0);
    setJobTitle(formData.jobTitle || '');
    setSummary(formData.summary || '');
  }, [formData]);

  const fetchOptions = async () => {
    const disciplines = await getDisciplines();
    const experiences = await getExperiences();
    const hobbies = await getHobbies();
    const offices = await getOffices();

    setExperienceOptions(experiences);
    setDisciplineOptions(disciplines);
    setHobbieOptions(hobbies);
    setOfficeOptions(offices);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const form = {
      firstName,
      lastName,
      duration,
      job_title,
      summary,
      discipline: optionsToValues(discipline),
      experience: optionsToValues(experience),
      hobbies: optionsToValues(hobbies),
      office: optionsToValues(office),
    };

    const result = await postRegisterForm(form);

    if (result === 401) {
      authDispatch({ type: 'LOG_OUT' });
    } else if (!result.error) {
      parseFormData(result);
      setStep(3);
    }
  };

  return (
    <div className="med-container">
      <form onSubmit={handleSubmit}>
        <p className="letter-space-1 gray">
          Please confirm that the following are correct:
          <span className="orange" style={{ fontStyle: 'italic' }}>
            {' required *'}
          </span>
        </p>
        <RegisterTextInput
          change={setFirstName}
          value={firstName}
          title={'First Name'}
          required={true}
          field={'firstName'}
        />
        <RegisterTextInput
          change={setLastName}
          value={lastName}
          title={'Last Name'}
          required={true}
          field={'lastName'}
        />
        <RegisterSelectInput
          change={setOffice}
          value={office}
          title={'Local Office'}
          required={true}
          field={'office'}
          options={officeOptions}
          chooseAll={false}
          customClass={'gray-multi-select w-480'}
          singleSelect={true}
          formData={formData}
        />
        <RegisterNumberInput
          change={setDuration}
          value={duration}
          title={'How many years have you been with the company?'}
          required={true}
          field={'duration'}
          min={0}
          max={100}
        />
        <RegisterTextInput
          change={setJobTitle}
          value={job_title}
          title={'What is your job title/role?'}
          required={true}
          field={'job_title'}
        />
        <RegisterSelectInput
          change={setDiscipline}
          value={discipline}
          formData={formData}
          title={'What is your professional discipline? '}
          required={true}
          field={'discipline'}
          options={disciplineOptions}
          chooseAll={false}
          customClass={'gray-multi-select w-175'}
        />
        <RegisterSelectInput
          change={setExperience}
          value={experience}
          formData={formData}
          title={'What are your areas of experience? '}
          required={true}
          field={'experience'}
          options={experienceOptions}
          customClass={'gray-multi-select w-175'}
        />
        <RegisterSelectInput
          change={setHobbies}
          value={hobbies}
          formData={formData}
          title={'What do you like to do in your free time? '}
          required={false}
          field={'hobbies'}
          options={hobbieOptions}
          customClass={'gray-multi-select w-175'}
        />
        <div>
          <p
            className="letter-space-1 gray"
            style={{ marginRight: '6px', whiteSpace: 'nowrap' }}
          >
            Tell us a little bit about yourself.
            <span style={{ fontStyle: 'italic' }}>
              {' (Max 500 characters)'}.
            </span>
          </p>
          <textarea
            className="gray-background"
            rows={5}
            maxLength={500}
            rows={5}
            onChange={(evt) => setSummary(evt.target.value)}
            value={summary}
          />
        </div>
        <div className="flex justify-content-center">
          <button className="tylin-button" onClick={() => setStep(1)}>
            {'Back'}
          </button>
          <button type="submit" className="tylin-button">
            {'Save & Continue'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterStepTwo;
