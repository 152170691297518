import React, { useEffect, useState } from 'react';
import { updateAuthRegistered } from '../../actions/authActions';
import config from '../../config/config';
import { useAuthStore } from '../../store/AuthStore';
import RegisterFileUpload from './RegisterFileUpload';

const RegisterStepThree = ({
  setStep,
  setLoading,
  formData,
  parseFormData,
}) => {
  const [state, dispatch] = useAuthStore();
  const [imageUrl, setImageUrl] = useState('/images/upload_preview.png');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setDisabled(!formData.photo_path);
  }, [formData]);

  useEffect(() => {
    if (formData.photo_path && formData.photo_path != '') {
      setImageUrl(`${config.thumbRoute}/${formData.photo_path}`);
    }
  }, [formData]);

  const handleCreateProfile = () => {
    dispatch(updateAuthRegistered());
  };

  return (
    <div className="med-container">
      <div className="flex justify-content-center">
        <img src={imageUrl} alt="upload preview" />
      </div>
      <div className="flex align-items-center">
        <p className="letter-space-1 gray">
          Upload a profile photo of yourself
          <span className="orange" style={{ fontStyle: 'italic' }}>
            {'*'} required
          </span>
        </p>
        <div style={{ marginLeft: '8px' }}>
          <form className="flex justify-content-center align-items-center">
            <RegisterFileUpload
              parseFormData={parseFormData}
              setError={setError}
              authDispatch={dispatch}
            />
            {error && (
              <p
                style={{
                  color: 'tomato',
                  fontSize: '14px',
                  marginLeft: '10px',
                }}
              >
                {error}
              </p>
            )}
          </form>
        </div>
      </div>
      <div>
        <ul className="photo-list">
          <p className="letter-space-1 gray">Choose a photo of yourself that meets the following requirements:</p>
          <div className="list">
            <li>
              <p className="letter-space-1 gray">
                Image format must be JPG
              </p>
            </li>
            <li>
              <p className="letter-space-1 gray">
                Minimum file size 0.1 MB
              </p>
            </li>
            <li>
              <p className="letter-space-1 gray">
                Maximum file size 10 MB
              </p>
            </li>
          </div>
        </ul>
        <p className="letter-space-1 gray">Once your photo has been successfully uploaded, a cropped preview of your photo should appear. This is how your photo will look on your profile page. If you are not satisfied, try uploading a different photo.</p>
        <p className="letter-space-1 gray">Once you are satisfied with how your profile image looks, click “CREATE PROFILE” to submit your information and photo.</p>
      </div>
      <div className="flex justify-content-center">
        <button className="tylin-button" onClick={() => setStep(2)}>
          {'Back'}
        </button>
        <button
          className="tylin-button"
          onClick={() => handleCreateProfile()}
          disabled={disabled}
          style={{ opacity: `${disabled ? 0.5 : 1}` }}
        >
          {'Create Profile'}
        </button>
      </div>
    </div>
  );
};

export default RegisterStepThree;
