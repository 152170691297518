import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import animateTriangles from '../utilities/animateTriangles';

const Pagination = ({ page, setPage, totalPages, nextPage, prevPage }) => {
  const goToNextPage = () => {
    setPage(page + 1);
    animateTriangles('out');
  };

  const goToPrevPage = () => {
    setPage(page - 1);
    animateTriangles('out');
  };

  return (
    <div>
      {page > 0 && (
        <div className="pagination-box">
          {prevPage && (
            <div className="prevPage page-button" onClick={goToPrevPage}>
              <FiChevronLeft className="page-arrow" />
            </div>
          )}
          <div className="currentPage page-of">
            <p>{`${page} OF ${totalPages}`}</p>
          </div>
          {nextPage && (
            <div className="nextPage page-button" onClick={goToNextPage}>
              <FiChevronRight className="page-arrow" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Pagination;
