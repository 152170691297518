import React from 'react';

const NotFound = () => {
  return (
    <div
      className="flex justify-content-center align-items-center flex-column"
      style={{ width: '100%', height: '100vh' }}
    >
      <img src="/tylin_logo.png" />
      <p>Page not found.</p>
    </div>
  );
};

export default NotFound;
