import { w as withSharedProps, _ as __rest, a as __assign } from './withSharedProps-a1728349.js';
import React from 'react';
import { s as styleInject, d as defaultProps } from './style-inject.es-fc9e633e.js';

var css_248z = "@keyframes spinners-react-round{27%,73%{r:2px;stroke-width:4}}";
styleInject(css_248z);

var Component = function (_a) {
    var speed = _a.speed, still = _a.still, thickness = _a.thickness, svgProps = __rest(_a, ["speed", "still", "thickness"]);
    var strokeWidth = 3 * (thickness / 100);
    var circleStyle = {
        animation: "spinners-react-round " + 140 / speed + "s ease-in-out infinite",
        transformOrigin: 'center',
    };
    if (still)
        circleStyle.animation = 'none';
    return (React.createElement("svg", __assign({ fill: "none" }, svgProps, { viewBox: "0 0 66 66" }),
        React.createElement("circle", { cx: "33", cy: "33", fill: "none", r: 28, stroke: "currentColor", strokeWidth: strokeWidth, style: circleStyle })));
};
Component.defaultProps = defaultProps;
var SpinnerRound = withSharedProps(Component);

export { SpinnerRound };
