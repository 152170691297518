import React from 'react';

const Welcome = ({ message }) => {
  return (
    <div style={{ padding: '0 20px' }}>
      <h1 className="giant-text">Welcome</h1>
      <p className="welcome-paragraph">
        This experience is intended to help connect our people and practices
        from all around the world.
      </p>
      <p className="welcome-paragraph">
        Use the TYLin Connect platform to filter and find colleagues with shared
        professional and personal interests.
      </p>
      <p className="welcome-paragraph" style={{ paddingBottom: '16px' }}>
        Together, we can imagine a stronger, more connected future. Together, we
        are TYLin.
      </p>
      <div className="flex justify-content-center">
        <div className={`error-box ${message ? 'show' : ''}`}>{message}</div>
      </div>
    </div>
  );
};

export default Welcome;
