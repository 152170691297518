import axios from 'axios';
import config from '../config/config';

export const axiosHeaders = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  crossDomain: true,
  timeout: 1000 * 20, // 20 second timeout
};

export const axiosHeadersFile = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
  crossDomain: true,
  timeout: 0, // 20 second timeout
};

// ======================
// GET
// ======================

export const getUserInfo = async () => {
  try {
    const { data } = await axios.get(`${config.getRegisterInfo}`, axiosHeaders);
    return data.triangle;
  } catch (err) {
    if (err.response.status === 401) {
      return 401;
    }

    console.log(`[ERROR] - Getting User Info, ${err}`);
  }
};

// SELECT OPTIONS
// -----------------------
export const getCountries = async () => {
  try {
    const { data } = await axios.get(`${config.fetchCountries}`, axiosHeaders);

    return data.countries;
  } catch (err) {
    console.log('Error retreiving country options.');
    return [];
  }
};

export const getDisciplines = async () => {
  try {
    const { data } = await axios.get(
      `${config.fetchDisciplines}`,
      axiosHeaders
    );

    return data.disciplines;
  } catch (err) {
    console.log('Error retreiving discipline options.');
    return [];
  }
};

export const getExperiences = async () => {
  try {
    const { data } = await axios.get(
      `${config.fetchExperiences}`,
      axiosHeaders
    );

    return data.experiences;
  } catch (err) {
    console.log('Error retreiving experience options.');
    return [];
  }
};

export const getHobbies = async () => {
  try {
    const { data } = await axios.get(`${config.fetchHobbies}`, axiosHeaders);

    return data.hobbies;
  } catch (err) {
    console.log('Error retreiving hobbie options.');
    return [];
  }
};

export const getOffices = async () => {
  try {
    const { data } = await axios.get(`${config.fetchOffices}`, axiosHeaders);

    return data.offices;
  } catch (err) {
    console.log('Error retreiving office options.');
    return [];
  }
};

export const getRegions = async () => {
  try {
    const { data } = await axios.get(`${config.fetchRegions}`, axiosHeaders);

    return data.regions;
  } catch (err) {
    console.log('Error retreiving region options.');
    return [];
  }
};

// ======================
// POST
// ======================

export const postRegisterForm = async (form) => {
  try {
    const { data } = await axios.post(
      `${config.postRegisterInfo}`,
      form,
      axiosHeaders
    );

    return data.triangle;
  } catch (err) {
    if (err.response.status === 401) {
      return 401;
    }

    console.log(`[ERROR] - Getting User Info, ${err}`);

    return { error: 'Error posting user info.' };
  }
};

export const postUploadFile = async (data) => {
  try {
    const result = await axios.post(
      `${config.postRegisterInfo}`,
      data,
      axiosHeadersFile
    );

    return result.data.triangle;
  } catch (err) {
    if (err.response.status === 401) {
      return 401;
    }

    console.log('[ERROR] - Posting image,', err);

    return { error: 'Error sending form info for photo.' };
  }
};

export const postCheckEmail = async (email) => {
  try {
    const { data } = await axios.post(
      `${config.checkEmailUrl}`,
      { email },
      axiosHeaders
    );

    return data.email;
  } catch (err) {
    console.log('Error while validating email', err);
    return { error: 'Server Error' };
  }
};

export const postFetchTriangles = async (params) => {
  try {
    const { data } = await axios.post(
      `${config.fetchTriangles}`,
      params,
      axiosHeaders
    );

    if (data.triangles.length === 0) {
      return { error: 'no results found' };
    } else {
      return data;
    }
  } catch (err) {
    if (err.response.status === 401) {
      return 401;
    }
    console.log('[ERROR] - fetching triangles,', err);
    return { error: 'Server Error' };
  }
};

export const postFetchDetails = async (id) => {
  try {
    const { data } = await axios.post(
      `${config.fetchDetails}`,
      { id: parseInt(id) },
      axiosHeaders
    );

    return data.triangle;
  } catch (err) {
    if (err.response.status === 401) {
      return 401;
    }
    console.log('[ERROR] - fetching details,', err);
    return { error: 'Server Error' };
  }
};
