import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import { postFetchDetails } from '../utilities/axios';
import config from '../config/config';
import { VscChromeClose } from 'react-icons/vsc';
import { useNavigate, useLocation } from 'react-router-dom';
import { decode } from 'html-entities';

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [fromWelcome, setFromWelcome] = useState();

  useEffect(() => {
    getDetails();
    setFromWelcome(!!location.state?.welcome);
  }, []);

  const getDetails = async () => {
    const details = await postFetchDetails(id);
    setDetails(details);
  };

  const handleBack = () => {
    if (fromWelcome) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className={'page-container'} stlye={{ position: 'relative' }}>
      <MainHeader />
      <div className="flex white-container" style={{ padding: '20px 0' }}>
        <div className="profile-back" onClick={handleBack}>
          <VscChromeClose />
        </div>
        <img
          src="/images/background.png"
          alt="background"
          className="background-triangles"
        />
        {Object.keys(details).length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '25px',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ flex: '1' }}>
              <img
                src={`${config.thumbRoute}/${details.photo_path}`}
                alt={'photo'}
                style={{ width: '350px', height: '350px' }}
              />
            </div>
            <div className="profile-wrapper">
              <h2 className={'profile_name'} style={{ textAlign: 'left' }}>
                {details.display_name || 'Not Provided'}
              </h2>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <p className="profile-job-title">{`${details.job_title}`}</p>
                  <div className="profile-small-divider"></div>
                  <p className="profile-job-title">{`${details.office.city}, ${details.country.name}`}</p>
                </div>
                <p className="profile-job-title">TYLin</p>
              </div>
              <div style={{ height: '36px' }}></div>
              <div>
                {details.email && details.email !== '' && (
                  <div className="profile-pairs" style={{ display: 'flex' }}>
                    <p className="profile-key" style={{ marginRight: '6px' }}>
                      Email:
                    </p>
                    <a href={`mailto:${details.email}`}>{details.email}</a>
                  </div>
                )}
                {details.region && (
                  <div className="profile-pairs">
                    <p className="profile-value">
                      <span className="profile-key">Region:</span>
                      {details.region}
                    </p>
                  </div>
                )}
                {details.duration && (
                  <div className="profile-pairs">
                    <p className="profile-value">
                      <span className="profile-key">
                        Years with the company:
                      </span>
                      {details.duration}
                    </p>
                  </div>
                )}
                {details.disciplines && details.disciplines.length > 0 && (
                  <div className="profile-pairs">
                    <p className="profile-value">
                      <span className="profile-key">
                        professional discipline(s):
                      </span>
                      {details.disciplines.map((el) => el.name).join(', ')}
                    </p>
                  </div>
                )}
                {details.experiences && details.experiences.length > 0 && (
                  <div className="profile-pairs">
                    <p className="profile-value">
                      <span className="profile-key">areas of experience:</span>
                      {details.experiences.map((el) => el.name).join(', ')}
                    </p>
                  </div>
                )}
                {details.hobbies && details.hobbies.length > 0 && (
                  <div className="profile-pairs">
                    <p className="profile-value">
                      <span className="profile-key">hobbies:</span>
                      {details.hobbies.map((el) => el.name).join(', ')}
                    </p>
                  </div>
                )}
                {details.summary && details.summary !== '' && (
                  <div className="profile-pairs">
                    <p className="profile-value">
                      <span className="profile-key">about me:</span>
                      {decode(details.summary)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
