import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import MainHeader from '../components/MainHeader';
import Triangles from '../components/triangles/Triangles';
import { useAuthStore } from '../store/AuthStore';

const Login = () => {
  const [state] = useAuthStore();
  const imageTriangles = [];

  for (let i = 0; i < 11; i++) {
    imageTriangles.push({});
  }

  return (
    <div className={'page-container'}>
      {state.isLoggedIn && <Navigate replace to="/register" />}
      <MainHeader />
      <div className="flex">
        <Triangles imageTriangles={imageTriangles} />
        <div style={{ width: '50%', padding: '0 125px 0 25px' }}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
