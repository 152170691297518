import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import InboundLoader from '../views/InboundLoader';
import Index from '../views/Index';
import Register from '../views/Register';
import PrivateRoute from './PrivateRoute';
import NotFound from '../views/NotFound';
import PrivacyPolicy from '../views/PrivacyPolicy';
import Login from '../views/Login';
import config from '../config/config';
import NoMobile from '../views/NoMobile';
import Profile from '../views/Profile';
import Article from '../views/Article';
import { verfiyEmailHash } from '../actions/authActions';
import { useAuthStore } from '../store/AuthStore';

const AppRouter = () => {
  const [state, dispatch] = useAuthStore();
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState('');
  const [isAuthCheck, setIsAuthCheck] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setIsMobile(width <= config.mobileBreakPoint);
  }, [width]);

  useEffect(() => {
    checkUserAuth();
  }, []);

  const checkUserAuth = async () => {
    const result = await verfiyEmailHash({});
    dispatch(result);
    setIsAuthCheck(true);
  };

  return (
    <div>
      {isAuthCheck && (
        <div>
          {isMobile && <NoMobile />}
          {!isMobile && (
            <BrowserRouter>
              <Routes>
                <Route path="/inbound" element={<InboundLoader />} />
                <Route
                  path="/register"
                  element={
                    <PrivateRoute>
                      <Register />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/"
                  element={
                    <PrivateRoute>
                      <Index />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/profile/:id"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/article/:id"
                  element={
                    <PrivateRoute>
                      <Article />
                    </PrivateRoute>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          )}
        </div>
      )}
    </div>
  );
};

export default AppRouter;
