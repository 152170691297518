import React, { useEffect, useRef } from 'react';
import triangleConfig from '../../config/triangleConfig';
import BackgroundTriangles from './BackgroundTriangles';
import ImageTriangles from './ImageTriangles';
import Parallax from 'parallax-js';
import LinkTriangles from './LinkTriangles';

const Triangles = ({ imageTriangles = [], linkTriangles = [] }) => {
  const scene = useRef(null);

  useEffect(() => {
    startParallax();
  }, [scene]);

  const startParallax = () => {
    const parallaxInstance = new Parallax(scene.current, {
      relativeInput: true,
      hoverOnly: true,
    });

    parallaxInstance.friction(0.2, 0.2);
    parallaxInstance.limit(16, 16);
  };

  return (
    <div
      id={'triangle-div'}
      ref={scene}
      style={{
        width: '640px',
        height: '640px',
      }}
    >
      <div
        style={{ position: 'relative', height: '100%', width: '100%' }}
        data-depth="0.9"
      >
        {triangleConfig.backgroundTriangles.map((tri, index) => (
          <BackgroundTriangles
            triangle={tri}
            index={index}
            key={`colTri-${index}`}
          />
        ))}
      </div>
      <div
        style={{ position: 'relative', height: '100%', width: '100%' }}
        data-depth="0.3"
      >
        {imageTriangles.map((triangle, index) => (
          <ImageTriangles
            key={`imgTri-${index}`}
            photo_path={triangle.photo_path}
            type_id={triangle.type_id}
            index={index}
            id={triangle.id}
            hidden={triangle.hidden}
          />
        ))}
      </div>
      <div
        style={{ position: 'relative', height: '100%', width: '100%' }}
        data-depth="0.5"
      >
        {linkTriangles.map((triangle, index) => (
          <LinkTriangles
            index={index}
            key={`linkTri-${index}`}
            id={triangle.id}
            hidden={triangle.hidden}
            type_id={triangle.type_id}
          />
        ))}
      </div>
    </div>
  );
};

export default Triangles;
