import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import { postFetchDetails } from '../utilities/axios';
import config from '../config/config';
import { VscChromeClose } from 'react-icons/vsc';
import { useNavigate, useLocation } from 'react-router-dom';
import parser, { Tag } from 'bbcode-to-react';

class PTag extends Tag {
  toReact() {
    return <p>{this.getComponents()}</p>;
  }
}

const Article = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [fromWelcome, setFromWelcome] = useState();

  parser.registerTag('p', PTag);

  useEffect(() => {
    getDetails();
    setFromWelcome(!!location.state?.welcome);
  }, []);

  const getDetails = async () => {
    const details = await postFetchDetails(id);
    setDetails(details);
  };

  const handleBack = () => {
    if (fromWelcome) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className={'page-container'} stlye={{ position: 'relative' }}>
      <MainHeader />
      <div className="flex white-container" style={{ padding: '20px 0' }}>
        <div className="profile-back" onClick={handleBack}>
          <VscChromeClose />
        </div>
        <img
          src="/images/background.png"
          alt="background"
          className="background-triangles"
        />
        {Object.keys(details).length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '25px',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ flex: '1' }}>
              <img
                src={`${config.thumbRoute}/${details.photo_path}`}
                alt={'photo'}
                style={{ width: '350px', height: '350px' }}
              />
            </div>
            <div className="profile-wrapper">
              <h2 className={'profile_name'} style={{ textAlign: 'left' }}>
                {details.display_name || 'Not Provided'}
              </h2>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <p className="profile-job-title">{`${details.job_title}`}</p>
                </div>
              </div>
              <div style={{ height: '36px' }}></div>
              <div>
                <div className="profile-pairs">
                  <p
                    className="profile-value"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {parser.toReact(details.summary)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Article;
