export const selectValueFromOptions = (values = [], options) => {
  if (values.length > 0) {
    return values
      .map((value) => {
        const result = options.find((option) => {
          return option.value === parseInt(value);
        });
        return result ? result : [];
      })
      .flat();
  } else {
    return [];
  }
};

export const optionsToValues = (optionsArray) => {
  return optionsArray.map((option) => option.value);
};
