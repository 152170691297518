import React, { useEffect, useState } from 'react';

const RegisterThankYou = () => {
  return (
    <div>
      <div className="med-container">
        <h2 style={{ textAlign: 'center' }}>Thank You</h2>
        <p className="letter-space-1 gray" style={{ textAlign: 'center' }}>
          Thank you for your help bringing TYLin Connect to life.
        </p>
        <p className="letter-space-1 gray" style={{ textAlign: 'center' }}>
          In March, you will receive an invitation to explore TYLin Connect and
          build intentional connections with members of your 2,000+TYLin
          colleagues around the world.
        </p>
      </div>
    </div>
  );
};

export default RegisterThankYou;
