import React, { useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import Triangles from '../components/triangles/Triangles';
import Welcome from '../components/Welcome';
import Filters from '../components/Filters';
import { postFetchTriangles } from '../utilities/axios';
import { useAuthStore } from '../store/AuthStore';
import Pagination from '../components/Pagination';
import { useSearchParams } from 'react-router-dom';
import animateTriangles from '../utilities/animateTriangles';

const Index = () => {
  const [state, dispatch] = useAuthStore();
  const [imageTriangles, setImageTriangles] = useState([]);
  const [backgroundTriangles, setBackgroundTriangles] = useState([]);
  const [linkTriangles, setLinkTriangles] = useState([]);
  const [page, setPage] = useState('');
  const [nextPage, setNextPage] = useState(false);
  const [prevPage, setPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromParams, setFromParams] = useState({});
  const [showTris, setShowTris] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    parseFiltersFromParams();
  }, [searchParams]);

  useEffect(() => {
    if (Object.keys(fromParams).length > 0) {
      fetchTriangles(fromParams);
    }
  }, [fromParams]);

  const fetchTriangles = async (params) => {
    setErrorMessage('');

    const result = await postFetchTriangles(params);

    if (result === 401) {
      dispatch({ type: 'LOG_OUT' });
    } else if (result.triangles && result.triangles.length === 0) {
      setErrorMessage('NO RESULTS');
      setTotalPages('');
      setNextPage(false);
      setPrevPage(false);
      setPage('');
    } else if (!result.error) {
      const { page, nextPage, prevPage, pageCount } = result.pagination;

      setImageTriangles(result.triangles);
      setLinkTriangles(result.trianglesOther);
      setPaginationParams(page, nextPage, prevPage, pageCount);
      animateTriangles('in');
      setShowTris(true);
    } else if (result.error) {
      setErrorMessage(result.error);
      setTotalPages('');
      setNextPage(false);
      setPrevPage(false);
      setPage(0);
    }
  };

  const setPaginationParams = (page, next, prev, total) => {
    setPage(page);
    setNextPage(next);
    setPrevPage(prev);
    setTotalPages(total);
  };

  const parseFiltersFromParams = () => {
    const arrayFields = [
      'offices',
      'regions',
      'experiences',
      'hobbies',
      'disciplines',
    ];

    const buildFilters = {};

    searchParams.forEach((value, key) => {
      if (arrayFields.includes(key)) {
        buildFilters[key] = value.split(',');
      } else {
        buildFilters[key] = value;
      }
    });

    if (Object.keys(buildFilters).length > 0) {
      setFromParams(buildFilters);
    } else {
      setPage(1);
      setFromParams({ page: '1' });
    }
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    const params = new URLSearchParams({ ...fromParams, page: newPage });
    setSearchParams(params);
  };

  return (
    <div className={'page-container'} style={{ paddingBottom: '20px' }}>
      <MainHeader />
      <div className="flex">
        <div style={{ visibility: showTris ? 'visible' : 'hidden' }}>
          <Triangles
            imageTriangles={imageTriangles}
            backgroundTriangles={[]}
            linkTriangles={linkTriangles}
          />
        </div>
        <div style={{ width: '50%', padding: '0 125px 0 25px' }}>
          <Welcome message={errorMessage} />
          <Filters page={page} fromParams={fromParams} />
        </div>
      </div>
      <div>
        <Pagination
          page={page}
          totalPages={totalPages}
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={handleSetPage}
        />
      </div>
    </div>
  );
};

export default Index;
