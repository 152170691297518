import React from 'react';
import SmallHeader from '../components/SmallHeader';

const NoMobile = () => {
  return (
    <div
      className={'page-container'}
      stlye={{ position: 'relative', padding: '16px 0' }}
    >
      <SmallHeader />
      <div className="flex white-container" style={{ maxWidth: '350px' }}>
        <img
          src="/images/background.png"
          alt="background"
          className="background-triangles"
        />
        <div style={{ margin: '0 auto', padding: '0 16px', height: '300px' }}>
          <p className="gray" style={{ textAlign: 'center' }}>
            We have designed TYLin Connect as an exclusive, TYLin experience. To
            keep TYLin Connect a secure place to share professional and personal
            information, please access TYLin Connect from your work computer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoMobile;
