import React from 'react';

function SmallHeader() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '30px 20px',
      }}
    >
      <img src="/tylin_logo.png" alt="logo" style={{ width: '130px' }} />
      <div className="divider"></div>
      <p
        style={{
          fontSize: '40px',
          margin: 0,
          fontWeight: 200,
          fontFamily: 'Spectral, serif',
        }}
      >
        Connect
      </p>
    </div>
  );
}

export default SmallHeader;
