import React from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';

const FilterWithInput = ({
  title,
  expand,
  change,
  name,
  placeholder,
  value,
}) => {
  return (
    <div
      style={{ position: 'relative', margin: '4px' }}
      className="filter-element"
    >
      <div className="filter-title-spacer">{title}</div>
      <div className="filter-box">
        <div className={`value-indicator ${value !== '' ? 'hasValue' : ''}`}>
          <FaCheck />
        </div>
        <p className="filter-title" onClick={expand}>
          {title}
        </p>
        <div className="filter-clear">
          <FaTimes />
        </div>
        <div style={{ padding: '10px' }}>
          <input
            className="tylin-input"
            placeholder={placeholder}
            name={name}
            onChange={(evt) => change(name, evt.target.value)}
            value={value}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterWithInput;
