import React, { useEffect, useRef, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { makeSingleSelect } from '../../utilities/helpers';
import { defaultItem } from './customSelectHelpers';

const RegisterSelectInput = ({
  change,
  value,
  formData,
  title,
  required,
  field,
  options,
  chooseAll = true,
  customClass = 'gray-multi-select',
  singleSelect = false,
}) => {
  const [validator, setValidator] = useState(false);

  useEffect(() => {
    const value = !!formData?.[field] ? formData?.[field] : [];
    const getValidator = value.length < 1 ? false : true;

    setValidator(getValidator);
  }, []);

  useEffect(() => {
    if (value.length > 0) {
      setValidator(true);
    } else {
      setValidator(false);
    }
  }, [value]);

  const handleChange = (value) => {
    if (singleSelect) {
      change(makeSingleSelect(value));
    } else {
      change(value);
    }
  };

  return (
    <div className="flex align-items-center justify-content-between">
      <p
        className="letter-space-1 gray"
        style={{ margin: '14px 20px 12px 0', whiteSpace: 'nowrap' }}
      >
        {' ' + title}
        {required ? <span className="orange">*</span> : ''}
        {chooseAll ? (
          <span style={{ fontStyle: 'italic' }}>
            {' Choose all that apply'}.
          </span>
        ) : (
          ''
        )}
      </p>
      <div style={{ position: 'relative' }}>
        <MultiSelect
          options={options}
          value={value}
          onChange={(value) => handleChange(value)}
          hasSelectAll={false}
          labelledBy={field}
          className={customClass}
          ItemRenderer={defaultItem}
        />
        <input
          type="input"
          className="multiselect-validator"
          required={required}
          defaultValue={validator ? '1' : ''}
        />
      </div>
    </div>
  );
};

export default RegisterSelectInput;
