import React from 'react';
import triangleConfig from '../../config/triangleConfig';
import { useNavigate } from 'react-router-dom';
import { generateTriangleLink } from '../../utilities/helpers';

const LinkTriangles = ({ index, id, type_id, hidden }) => {
  const indexAdjust = triangleConfig.backgroundTriangles.length;
  const x = Math.round(
    triangleConfig.containerX * triangleConfig.linkTriangles[index].x
  );
  const y = Math.round(
    triangleConfig.containerY * triangleConfig.linkTriangles[index].y
  );
  const navigate = useNavigate();
  const parsedLink = generateTriangleLink(type_id, id);

  return (
    <div
      className={`triangle-wrapper tc-${index + indexAdjust}`}
      data-x={x}
      data-y={y}
    >
      <div
        className={`triangle pointer grow`}
        onClick={() => navigate(parsedLink, { state: { welcome: true } })}
      ></div>
    </div>
  );
};

export default LinkTriangles;
