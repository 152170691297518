import React, { useState } from 'react';
import { postCheckEmail } from '../utilities/axios';

const LoginForm = () => {
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const result = await postCheckEmail(email);

    if (result.valid) {
      setMessage('EMAIL SENT');
    } else if (result.valid === false) {
      setDisabled(false);
      setMessage('NOT FOUND');
    } else if (result.error) {
      setDisabled(false);
      setMessage(result.error);
    }
  };

  return (
    <div>
      <div style={{ padding: '50px 10px 0 10px' }}>
        <h1 className="giant-text">Login</h1>
        <p className="welcome-paragraph" style={{ paddingBottom: '16px' }}>
          Welcome to TYLin Connect! This experience is intended to help connect
          our people and practices from all around the world.
        </p>
        <p className="welcome-paragraph" style={{ paddingBottom: '16px' }}>
          To gain access to the platform, you must use your work computer.
        </p>
        <p className="welcome-paragraph" style={{ paddingBottom: '16px' }}>
          Please enter your company email address below and you will be sent a
          link via email to access TYLin Connect.
        </p>
      </div>
      <div className="flex justify-content-center">
        <div className={`error-box ${message ? 'show' : ''}`}>{message}</div>
      </div>
      <div
        className="flex justify-content-center"
        style={{ padding: '16px 0' }}
      >
        <input
          type="text"
          className="tylin-input gray-background"
          onChange={handleChange}
          value={email}
          name={'email'}
          disabled={disabled}
          style={{ maxWidth: '325px' }}
          placeholder="firstname.lastname@tylin.com"
        />
      </div>
      <div className="flex justify-content-center">
        <button
          className="tylin-button"
          onClick={(e) => {
            setDisabled(true);
            handleSubmit(e);
          }}
          style={{ opacity: disabled ? '0.5' : '1' }}
          disabled={disabled}
        >
          SEND LINK
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
