import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getNumberBetween,
  handleExpand,
  handleCloseOpened,
} from '../utilities/helpers';
import FilterDivider from './FilterDivider';
import FilterWithInput from './FilterWithInput';
import FilterWithRange from './FilterWithRange';
import FilterWithSelect from './FilterWithSelect';
import {
  getRegions,
  getDisciplines,
  getExperiences,
  getHobbies,
  getOffices,
} from '../utilities/axios';
import animateTriangles from '../utilities/animateTriangles';
import { selectValueFromOptions } from '../utilities/optionsHelpers';

const Filters = ({ fromParams }) => {
  const defaultOptions = {
    disciplines: [],
    regions: [],
    hobbies: [],
    offices: [],
    ecperiences: [],
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectOptions, setSelectOptions] = useState(defaultOptions);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchSelectOptions();
  }, []);

  useEffect(() => {
    setFiltersFromParams();
  }, [selectOptions]);

  useEffect(() => {
    document.addEventListener('click', (evt) => {
      const isFilterDiv = evt.target.classList.contains('filter-element');
      const isFilterDivChild = !!evt.target.closest('.filter-element');

      if (!isFilterDiv && !isFilterDivChild) {
        handleCloseOpened();
      }
    });
  }, []);

  const fetchSelectOptions = async () => {
    const disciplines = await getDisciplines();
    const experiences = await getExperiences();
    const hobbies = await getHobbies();
    const offices = await getOffices();
    const regions = await getRegions();

    setSelectOptions({
      disciplines,
      experiences,
      hobbies,
      offices,
      regions,
    });

    setFiltersFromParams();
  };

  const setFiltersFromParams = () => {
    const arrayFields = [
      'offices',
      'regions',
      'experiences',
      'hobbies',
      'disciplines',
    ];

    const tempFilters = {};

    for (let param in fromParams) {
      if (param !== 'page' && param !== 'search') {
        if (arrayFields.includes(param)) {
          tempFilters[param] = selectValueFromOptions(
            fromParams[param],
            selectOptions[param]
          );
        } else {
          tempFilters[param] = fromParams[param];
        }
      }
    }

    setFilters(tempFilters);
  };

  const handleUpdateFilters = (field, value) => {
    if (
      typeof value == 'string' &&
      value !== '' &&
      value !== '0' &&
      value !== 0
    ) {
      setFilters({ ...filters, [field]: value });
    } else if (Array.isArray(value) && value.length !== 0) {
      setFilters({ ...filters, [field]: value });
    } else if (filters.hasOwnProperty(field)) {
      let tempFilters = { ...filters };
      delete tempFilters[field];
      setFilters(tempFilters);
    }
  };

  const handleApplyFilters = () => {
    const tempFilters = {
      ...filters,
      page: '1',
      search: getNumberBetween(1, 1000),
    };

    for (let f in tempFilters) {
      const value = tempFilters[f];

      if (Array.isArray(value)) {
        tempFilters[f] = value.map((el) => el.value).join(',');
      }
    }

    const params = new URLSearchParams(tempFilters);

    setSearchParams(params);

    animateTriangles('out');
  };

  return (
    <div>
      <div style={{ padding: '0 20px' }}>
        <FilterDivider title="LOCATION" />
        <div className="filter-section-container">
          <FilterWithSelect
            name="regions"
            title={'REGION'.trim()}
            expand={handleExpand}
            options={selectOptions.regions || []}
            value={filters.regions || []}
            change={handleUpdateFilters}
          />
          <FilterWithSelect
            name="offices"
            title="LOCAL OFFICE"
            expand={handleExpand}
            options={selectOptions.offices || []}
            value={filters.offices || []}
            change={handleUpdateFilters}
          />
        </div>
      </div>
      <div style={{ padding: '0 20px' }}>
        <FilterDivider title="PROFESSIONAL" />
        <div className="filter-section-container">
          <FilterWithSelect
            name="disciplines"
            title="PROFESSIONAL DISCIPLINE"
            expand={handleExpand}
            options={selectOptions.disciplines || []}
            value={filters.disciplines || []}
            change={handleUpdateFilters}
          />
          <FilterWithSelect
            name="experiences"
            title="AREA OF EXPERIENCE"
            expand={handleExpand}
            options={selectOptions.experiences || []}
            value={filters.experiences || []}
            change={handleUpdateFilters}
          />
          <FilterWithRange
            name="years"
            title="YEARS WITH COMPANY"
            expand={handleExpand}
            value={filters.years || '0'}
            change={handleUpdateFilters}
          />
        </div>
      </div>
      <div style={{ padding: '0 20px' }}>
        <FilterDivider title="PERSONAL" />
        <div className="filter-section-container">
          <FilterWithSelect
            name="hobbies"
            title="HOBBIES"
            expand={handleExpand}
            options={selectOptions.hobbies || []}
            value={filters.hobbies || []}
            change={handleUpdateFilters}
          />
          <FilterWithInput
            name="name"
            title="NAME SEARCH"
            expand={handleExpand}
            placeholder="Enter name"
            change={handleUpdateFilters}
            value={filters.name || ''}
          />
        </div>
      </div>
      <div className="flex justify-content-center">
        <button
          type="button"
          className="tylin-button"
          onClick={handleApplyFilters}
        >
          APPLY FILTERS
        </button>
      </div>
    </div>
  );
};

export default Filters;
