import Checkbox from 'react-custom-checkbox';
import { FaCheck } from 'react-icons/fa';

const defaultItem = ({ checked, option, onClick, disabled }) => {
  return (
    <div
      className={`item-renderer ${
        disabled && 'disabled'
      } flex align-items-center`}
    >
      <Checkbox
        icon={
          <div
            className="flex justify-content-center align-items-center"
            style={{ color: '#f26724' }}
          >
            <FaCheck />
          </div>
        }
        checked={checked}
        onChange={onClick}
        size={16}
        borderRadius={0}
        borderWidth={0}
        style={{ background: 'white', marginRight: '6px' }}
        containerStyle={{ cursor: 'pointer' }}
      />
      <span className="gray-3">{option.label}</span>
    </div>
  );
};

export { defaultItem };
