export default {
  mobileBreakPoint: 1180,
  verifyHashUrl: 'https://api.tylinconnect.com/hashes/validate.json',
  postRegisterInfo: 'https://api.tylinconnect.com/triangles/active.json',
  getRegisterInfo: 'https://api.tylinconnect.com/triangles/active.json',
  fetchCountries: 'https://api.tylinconnect.com/countries/index.json',
  fetchDisciplines: 'https://api.tylinconnect.com/disciplines/index.json',
  fetchExperiences: 'https://api.tylinconnect.com/experiences/index.json',
  fetchHobbies: 'https://api.tylinconnect.com/hobbies/index.json',
  fetchOffices: 'https://api.tylinconnect.com/offices/index.json',
  fetchRegions: 'https://api.tylinconnect.com/regions/index.json',
  fetchDetails: 'https://api.tylinconnect.com/triangles/detail.json',
  thumbRoute: 'https://api.tylinconnect.com/thumb',
  checkEmailUrl: 'https://api.tylinconnect.com/hashes/email.json',
  privacyPolicyLink: '/privacy-policy',
  fetchTriangles: 'https://api.tylinconnect.com/triangles/welcome.json',
};
