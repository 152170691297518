import React from 'react';
import MainHeader from '../components/MainHeader';

const PrivacyPolicy = () => {
  return (
    <div className={'page-container'} stlye={{ position: 'relative' }}>
      <MainHeader />
      <div className="flex white-container">
        <img
          src="/images/background.png"
          alt="background"
          className="background-triangles"
        />
        <div style={{ margin: '0 auto' }}>
          <div>
            <div className="med-container">
              <h2 style={{ textAlign: 'left' }}>Privacy Policy</h2>
              <p className="pp-section-title">
                TYLIN CONNECT PLATFORM PRIVACY POLICY
              </p>
              <p className="gray">
                This Privacy Policy provides information about personal
                information that T.Y. Lin International Group Ltd. and its
                Affiliates ("TYLI") collects, uses, and shares from its TYLin
                Connect platform (“Platform”) and our commitment to using the
                personal information we collect in a respectful fashion.
                "Affiliate" is defined as a business entity currently existing
                or later acquired that is under the common control of TYLI.
              </p>

              <p className="pp-section-title numbered">
                1. WHAT INFORMATION DO WE COLLECT?
              </p>
              <p className="gray">
                We collect personal information that you voluntarily provide to
                TYLI, including the following: name, email address, region,
                local office, job title/role, and photographs. We pre-populate
                some content based upon information previously provided to TYLI
                Human Resources, including (but not limited to) First Name, Last
                Name, and Business Email Address. This data is collected ahead
                of time to ease registration and for user validation, and
                information is not included on the Platform until you provide
                consent. Any other personal information is collected directly
                from you on the Platform.
              </p>

              <p className="pp-section-title numbered">
                2. WHAT DO WE USE YOUR INFORMATION FOR?
              </p>
              <p className="gray">
                The information that is collected from you will be used to
                facilitate participation in Platform information sharing,
                consistent with this Privacy Policy.
              </p>

              <p className="pp-section-title numbered">3. DO WE USE COOKIES?</p>
              <p className="gray">
                The Platform uses cookies to provide necessary website
                functionality.
              </p>

              <p className="pp-section-title numbered">
                4. DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES??
              </p>
              <p className="gray">
                We do not sell, trade, or otherwise transfer to outside parties
                personal information collected through the Platform. This does
                not include trusted third parties who assist us in operating
                TYLI Connect, so long as those parties agree to keep this
                information confidential. We may also release your information
                when we believe release is appropriate to comply with the law,
                enforce our site policies, or protect TYLI’s or others’ rights,
                property, or safety.
              </p>

              <p className="pp-section-title numbered">
                5. PLATFORM PRIVACY POLICY ONLY
              </p>
              <p className="gray">
                This Privacy Policy applies only to information collected
                through the Platform.
              </p>

              <p className="pp-section-title numbered">6. YOUR CONSENT</p>
              <p className="gray">
                By using the Platform, you consent to this Privacy Policy.
              </p>

              <p className="pp-section-title numbered">
                7. UPDATES TO AND DELETION OF PERSONAL INFORMATION
              </p>
              <p className="gray">
                If you are unable to update or delete your information on the
                Platform, you may request that TYLI update or delete this
                information using the contact information below. Please note
                that any such communication must be in writing. In your request,
                please make clear what specific personal information you would
                like to have updated or deleted.
              </p>
              <p className="gray">
                Your right to request that TYLI update or delete the personal
                information on the Platform is not absolute. There are instances
                where applicable law or regulatory requirements allow or require
                us to refuse your request. If we cannot delete or update your
                personal information, we will endeavor to inform you of the
                reasons why, subject to any legal or regulatory restrictions.
              </p>

              <p className="pp-section-title numbered">
                8. CHANGES TO PRIVACY POLICY
              </p>
              <p className="gray">
                If we decide to change this Privacy Policy, we will post those
                changes on this page.
              </p>

              <p className="pp-section-title numbered">9. CONTACTING TYLI</p>
              <p className="gray">
                If there are any questions regarding this Privacy Policy, you
                may contact us using the information below.
              </p>
              <a href="http://www.tylin.com">Website</a>
              <p style={{ margin: 0 }} className="gray">
                345 California Street, Suite 2300
              </p>
              <p style={{ margin: 0 }} className="gray">
                San Francisco, CA 94104
              </p>
              <p style={{ margin: 0 }} className="gray">
                USA
              </p>
              <p style={{ margin: 0 }} className="gray">
                +1.415.291.3700
              </p>

              <p className="pp-section-title numbered">CALIFORNIA RESIDENTS</p>
              <p className="gray">
                Pursuant to the California Consumer Privacy Act of 2018
                (“CCPA”), below is a summary of the personal information we
                collect from “consumers” as defined under the CCPA, the reason
                we collect this personal information, where we obtain this
                personal information, and the third parties with whom we may
                share consumer personal information. The section references
                relate to the sections above in this Privacy Policy.
              </p>
              <table style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th>
                      <p>Personal Information</p>
                    </th>
                    <th>
                      <p>Information Collected </p>
                    </th>
                    <th>
                      <p>Reason for Collection</p>
                    </th>
                    <th>
                      <p>Outside Parties</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={{ background: '#e3e3e3' }}>
                      <p className="gray" style={{ fontWeight: '400' }}>
                        Identifiers such as a name, email address, region, local
                        office, and job title, photographs.
                      </p>
                    </th>
                    <th style={{ background: '#e3e3e3' }}>
                      <p className="gray" style={{ fontWeight: '400' }}>
                        Section 1
                      </p>
                    </th>
                    <th style={{ background: '#e3e3e3' }}>
                      <p className="gray" style={{ fontWeight: '400' }}>
                        Section 2
                      </p>
                    </th>
                    <th style={{ background: '#e3e3e3' }}>
                      <p className="gray" style={{ fontWeight: '400' }}>
                        Section 4
                      </p>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
