import React from 'react';

const RegisterNumberInput = ({
  change,
  value,
  title,
  required,
  field,
  max,
  min,
}) => {
  return (
    <div className="flex align-items-center">
      <p
        className="letter-space-1 gray"
        style={{ margin: '12px 20px 12px 0', whiteSpace: 'nowrap' }}
      >
        {' ' + title}
        {required ? <span className="orange">*</span> : ''}
      </p>
      <input
        type="number"
        className="tylin-input gray-background"
        min={min}
        man={max}
        value={value}
        onChange={(evt) => change(evt.target.value)}
        required={required}
        name={field}
      />
    </div>
  );
};

export default RegisterNumberInput;
